import React from 'react'
import CustomMenu from "../CustomMenu/index";
const menus = [
    {
      title: '首页',
      icon: 'HomeOutlined',
      key: '/home'
    },
    {
      title: '商品管理',
      icon: 'AppstoreOutlined',
      key: 'sub1',
      subs: [
        {key: '/product/all/sku', title: '本地商品', icon: '',},
        {key: '/product/tmallskinmira/sku', title: '天猫skinmira商品', icon: '',},
        {key: '/product/tmallcharme/sku', title: '天猫charme商品', icon: '',},
       // {key: '/product/StandardProduct/sku', title: '天猫charme标品', icon: '',},
        {key: '/product/approval/sku', title: '天猫skinmira调价一栏', icon: '',},
        {key: '/product/photo', title: '图片管理', icon: '',},
        {key: '/product/mark', title: '品牌管理', icon: '',},
      ]///
    },
    {
      title: '订单管理',
      icon: 'UnorderedListOutlined',
      key: 'sub2',
      subs: [
        {key: '/order/all', title: '所有订单', icon: '',},
        {key: '/Box/waybill', title: '待生成面单', icon: '',},
       // {key: '/order/senser', title: '识季订单', icon: '',},
        {key: '/order/fastcheck', title: '订单查询', icon: '',},
      ]
    },
     {
      title: '仓储管理',
      icon: 'DatabaseOutlined',
      key: 'sub3',
      subs: [
        {key: '/stock/stockEdit', title: '库存记录', icon: ''},
        {key: '/stock/Checktostock', title: '扫货入库', icon: ''},
        {key: '/stock/command', title: '采购入库', icon: ''},
        {key: '/purchase/returner', title: '采购退货', icon: ''},
      ]
    },
    {
      title: '采购管理',
      icon: 'ShoppingOutlined',
      key: 'sub4',
      subs: [
        {key: '/purchase/luxury', title: '待接单', icon: ''},
        {key: '/purchase/sku', title: '待采购', icon: ''},
        {key: '/purchase/command', title: '采购订单', icon: ''},
      
        {key: '/purchase/account', title: '采购账号', icon: ''},
        {key: '/purchase/pickup', title: '取货点', icon: ''},
        {key: '/purchase/fournisseur', title: '供应商', icon: ''},
        {key: '/purchase/stockingUp', title: '商品备货', icon: ''},
      ]
    },
    {
      title: '包裹管理',
      icon: 'DropboxOutlined',
      key: 'sub6',
      subs: [
       
        {key: '/Box/checking', title: '待扫货', icon: ''},
        {key: '/Box/sorting', title: '待分拣', icon: ''},
       {key: '/Box/weighing', title: '待称重', icon: ''},
       {key: '/Box/box', title: '查看装箱', icon: ''},
       {key: '/Box/returner', title: '待退货', icon: ''},
      ]
    },

    {
      title: '发票管理',
      icon: 'GoldOutlined',
      key: 'sub7',
      subs: [
        {key: '/invoice/liste ', title: '销售发票', icon: ''},

      ]
    },  
    {
      title: '资金管理',
      icon: 'EuroCircleOutlined',
      key: 'sub8',
      subs: [
        {key: '/fonds/liste ', title: '收支详情', icon: ''},

      ]
    }, 
    {
      title: '人事管理',
      icon: 'TeamOutlined',
      key: 'sub9',
      subs: [
        {key: '/account/liste ', title: '账号权限', icon: ''},

      ]
    },  
    {
      title: '爬虫管理',
      icon: 'AndroidOutlined',
      key: 'sub11',
      subs: [
        {key: '/product/grap/sku ', title: '商品推送', icon: ''},
        {key: '/product/grap/setting ', title: '爬虫设置', icon: ''},
      ]
    },  
    {
      title: 'rare paris',
      icon: 'DatabaseOutlined',
      key: 'sub12',
      subs: [
        {key: '/product/gros/sku ', title: '商品/Produit', icon: ''},
        {key: '/product/gros/commande ', title: '订单/Commande', icon: ''},

        {key: '/product/gros/client ', title: '客户管理/Client', icon: ''},
        {key: '/product/gros/stockEdit ', title: '出入库/Stock', icon: ''},
      ]
    },  
    {
      title: '寄售管理',
      icon: 'DatabaseOutlined',
      key: 'sub13',
      subs: [
        {key: '/product/consignment/commande ', title: '入库订单', icon: ''},
        {key: '/product/consignment/stockEdit ', title: '出入库记录', icon: ''},
      ]
    },  
  ]



class SiderNav extends React.Component {

  render() {

    return (
      <div style={{height: '100vh',overflowY:'hidden',overflowX:'hidden'}}>
       <div style={styles.logo}></div>
        <CustomMenu menus={menus}/>
      </div>
    )
  }
}
//
const styles = {
  logo: {
    height: '35px',
    background: 'rgba(255, 255, 255, .2)',
    margin: '16px'
  }
}

export default SiderNav