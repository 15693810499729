//import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import LoadableComponent from './utils/LoadableComponent'
//import { getLodop } from './utils/LodopFuncs';
import { message } from 'antd'; 
import {
  Routes,
  Route
} from "react-router-dom";
import { useNavigate,useLocation } from "react-router-dom";
//import UserProfile from './utils/Session'
import Login from './routes/login/index'
import Index from './routes/index/index'
import { hasPermission } from './utils/permissions';


//const Login = LoadableComponent(()=>import('./routes/Login/index'))
const Home = LoadableComponent(()=>import('./routes/home/index')) //Ordersenser
const Nopermission = LoadableComponent(()=>import('./routes/errorPage/index'))
const Order = LoadableComponent(()=>import('./routes/order/index'))  
const Ordersenser = LoadableComponent(()=>import('./routes/order/senser'))  
const Ordercheck = LoadableComponent(()=>import('./routes/order/fastcheck'))  
const Productsensor = LoadableComponent(()=>import('./routes/product/sensor/sku/index'))  
const ProductTmall = LoadableComponent(()=>import('./routes/product/tmall/sku/index'))

const ProductTmallapproval = LoadableComponent(()=>import('./routes/product/approval/sku/index'))
const ProductTmallapprovalcharme = LoadableComponent(()=>import('./routes/product/approvalcharme/sku/index'))

const StandardProduct = LoadableComponent(()=>import('./routes/product/standardProduct/sku/index'))


const Productxhs = LoadableComponent(()=>import('./routes/product/xhs/sku/index'))
const Productdewu = LoadableComponent(()=>import('./routes/product/dewu/sku/index'))

const Productall = LoadableComponent(()=>import('./routes/product/all/sku/index'))  
const Productgros = LoadableComponent(()=>import('./routes/product/gros/sku/index'))  
const GrosStockEdit = LoadableComponent(()=>import('./routes/product/gros/stockEdit/index'))  
const GrosCommande  = LoadableComponent(()=>import('./routes/product/gros/commande/index'))  
const GrosClient  = LoadableComponent(()=>import('./routes/product/gros/client/index'))  

const ConsignmentStockEdit = LoadableComponent(()=>import('./routes/product/consignment/stockEdit/index'))  
const ConsignmentCommande  = LoadableComponent(()=>import('./routes/product/consignment/commande/index'))  
const ConsignmentSku  = LoadableComponent(()=>import('./routes/product/consignment/sku/index'))  
const ConsignmentOrder  = LoadableComponent(()=>import('./routes/product/consignment/order/index'))  

const Grapsku = LoadableComponent(()=>import('./routes/product/grap/sku/index'))  
const Grapproduct = LoadableComponent(()=>import('./routes/product/grap/product/index'))  
const Grapsetting = LoadableComponent(()=>import('./routes/product/grap/setting/index'))  

const Mark = LoadableComponent(()=>import('./routes/product/mark/index'))  
const Photo = LoadableComponent(()=>import('./routes/product/photo/index.js'))  

const StockEdit = LoadableComponent(()=>import('./routes/stock/stockEdit/index'))  
const Waybill = LoadableComponent(()=>import('./routes/Box/waybill/index')) 
const Checking = LoadableComponent(()=>import('./routes/Box/checking/index')) 
const Sorting = LoadableComponent(()=>import('./routes/Box/sorting/index')) 
const Weighing = LoadableComponent(()=>import('./routes/Box/weighing/index'))
const Box = LoadableComponent(()=>import('./routes/Box/box/index'))
const Purchasesku = LoadableComponent(()=>import('./routes/purchase/sku/index'))  
const PurchaseCommand = LoadableComponent(()=>import('./routes/purchase/command/index')) 
const PurchaseFournisseur = LoadableComponent(()=>import('./routes/purchase/fournisseur/index')) 
const PurchaseStockingUp = LoadableComponent(()=>import('./routes/purchase/stockingUp/index')) 
const Purchasereturner = LoadableComponent(()=>import('./routes/purchase/Preturner/index')) 
const PurchaseAccount = LoadableComponent(()=>import('./routes/purchase/account/index')) 
const PurchasePickup = LoadableComponent(()=>import('./routes/purchase/pickup/index')) 
const Purchaseluxury = LoadableComponent(()=>import('./routes/purchase/luxury/index')) 

const Returner = LoadableComponent(()=>import('./routes/Box/returner/index')) 
const Invoice = LoadableComponent(()=>import('./routes/invoice/liste/index')) 
const Fonds = LoadableComponent(()=>import('./routes/fonds/liste/index')) 
const Account = LoadableComponent(()=>import('./routes/account/liste/index')) 

const StockCommand = LoadableComponent(()=>import('./routes/stock/command/index')) 
const Checktostock = LoadableComponent(()=>import('./routes/stock/checktostock/index')) 
/*
PurchaseFournisseur
const Packaging = LoadableComponent(()=>import('./routes/Box/packaging/index')) 
const Weighing = LoadableComponent(()=>import('./routes/Box/weighing/index'))
const Boxing = LoadableComponent(()=>import('./routes/Box/boxing/index')) 
const Box = LoadableComponent(()=>import('./routes/Box/box/index')) */

function App() {
  let navigation = useNavigate();
  let location = useLocation();

  //const login  = UserProfile.getLogin()


  useEffect(() => {
    if(!window.sessionStorage.login  && location.pathname !=='/admin-login' && window.sessionStorage.Firstlogin ){

      message.info("登录已过期,请重新登录")
      setTimeout(() => {
        navigation("/admin-login")
      }, 2000);
    }
    /*if(window.sessionStorage.login ==="false" && location.pathname !=='/admin-login' && window.sessionStorage.Firstlogin ){

      message.info("登录已过期,请重新登录")
      setTimeout(() => {
        navigation("/admin-login")
      }, 2000);
    }*/

  }, [location.pathname,navigation]);


       /* const test = () => {
  var strURL ="http://importwaybillfile.oss-cn-zhangjiakou.aliyuncs.com/imWaybill_LP00582288172607.pdf?Expires=1690794378&OSSAccessKeyId=LTAI4FxhjStXr6WKJTVRfdzP&Signature=J4oTMJk3J%2FH5VaeUKcS0QpXoeEM%3D"
      let LODOP = getLodop();
      LODOP.SET_PRINTER_INDEX("ZDesigner GK888t")
      LODOP.PRINT_INIT("打印控件功能演示_Lodop功能_分页输出一");
      LODOP.SET_PRINT_PAGESIZE(1,1000,300,"");  
      LODOP.ADD_PRINT_PDF(1,1,1,1,strURL)
      //LODOP.ADD_PRINT_HTM(1,60,350,600,"barcodeRef"."current.outerHTML");
  
      LODOP.PRINT();	  //最后一个打印(预览)语句
      
      //    LODOP.PRINT();	
      //element={window.sessionStorage.login === 'true'?<Index />://<Route path="/" element={<PublicPage />}>   </Route>
      };*/ 
      //UserProfile.setLogin(false)
    //console.log(window.sessionStorage.login)

    const pathname = (name) => {
     
      if(window.sessionStorage.login !=='true')return <PublicPage />
      if(!window.sessionStorage.Firstlogin)  navigation("/admin-login")
      switch (name) {
        case "index":
          return <Index />
        case "home":
          return <Home />
        case "Order":
          return <Order /> 
        case "Ordersenser":
          return <Ordersenser /> 
        case "Ordercheck":
          return <Ordercheck /> 
            
        case "productsensor":
          return <Productsensor />
        case "productdewu":
          return <Productdewu />
        case "Productall":
          return <Productall />
        case "Grapsku":
          return <Grapsku />
          case "Grapproduct":
            return <Grapproduct />    
        case "Grapsetting":
          return <Grapsetting />   
        case "Xhs":
          return <Productxhs />
        case "Mark":
          return <Mark />
        case "Photo":
          return <Photo />
        case "Productgros":
          return <Productgros />
        case "GrosStockEdit":
          return <GrosStockEdit />
        case "GrosCommande":
          return <GrosCommande />

        case "GrosClient":
          return <GrosClient />

        case "ConsignmentStockEdit":
          return <ConsignmentStockEdit />
        case "ConsignmentCommande":
          return <ConsignmentCommande />
        case "ConsignmentOrder":
          return <ConsignmentOrder />
          case "ConsignmentSku":
            return <ConsignmentSku />
        case "ProductTmallskinmira":
          return <ProductTmall index={"tmall"} />
        case "ProductTmallcharme":
          return <ProductTmall index={"tmallcharme"} />
        case "ProductTmallapproval":
            return <ProductTmallapproval  />
          case "ProductTmallapprovalcharme":
            return <ProductTmallapprovalcharme  />
        case "StandardProduct":
          return <StandardProduct  />  
        case "StockEdit":
          return <StockEdit />
        case "Waybill":
          return <Waybill />
        case "Checking":
          return <Checking />
        case "Sorting":
          return <Sorting />
        case "Weighing":
          return <Weighing />
        case "Box":
          return <Box />
        case "StockCommand":
          return <StockCommand />  
        case "Checktostock":
          return <Checktostock />  
        case "Purchasesku":
          return <Purchasesku />
        case "PurchaseCommand":
          return <PurchaseCommand />
        case "fournisseur":
          return <PurchaseFournisseur />
        case "account":
          return <PurchaseAccount />
        case "pickup":
          return <PurchasePickup />
        case "luxury":
          return <Purchaseluxury />
        case "stockingUp":
          return <PurchaseStockingUp />  
        case "returner":
          return <Returner />
        case "Invoice":
          return <Invoice />
        case "Fonds":
          return <Fonds />
        case "Account":
          return  <Account />     
        case "Preturner":
          return  <Purchasereturner />       
        default:
          <PublicPage />
      }
    }

    const renderComponentBasedOnPermissions = (permis,name) => {

      if (hasPermission( permis)) {
      
        return pathname(name);
      } else {
        return <Nopermission />;
      }
    };

  return (
    
    <Routes >
   
   <Route path="/admin-login" element={<Login />} />
      <Route path="/" element={pathname("index")} >
        <Route path="/home" element={pathname("home")}></Route>
        <Route path="/order/all" element={renderComponentBasedOnPermissions("command","Order")}></Route>
        <Route path="/order/fastcheck" element={renderComponentBasedOnPermissions("command","Ordercheck")}></Route>
        <Route path="/order/senser" element={renderComponentBasedOnPermissions("command","Ordersenser")}></Route>
        <Route path="/product/sensor/sku" element={renderComponentBasedOnPermissions("product","productsensor")}></Route>
        <Route path="/product/all/sku" element={renderComponentBasedOnPermissions("product","Productall")}></Route>
        <Route path="/product/gros/sku" element={renderComponentBasedOnPermissions("rare","Productgros")}></Route>
        <Route path="/product/gros/stockEdit" element={renderComponentBasedOnPermissions("rare","GrosStockEdit")}></Route>
        <Route path="/product/gros/commande" element={renderComponentBasedOnPermissions("rare","GrosCommande")}></Route>
        <Route path="/product/gros/client" element={renderComponentBasedOnPermissions("rare","GrosClient")}></Route>
        <Route path="/product/dewu/sku" element={renderComponentBasedOnPermissions("product","productdewu")}></Route>
       
        <Route path="/product/consignment/stockEdit" element={renderComponentBasedOnPermissions("product","ConsignmentStockEdit")}></Route>
        <Route path="/product/consignment/commande" element={renderComponentBasedOnPermissions("product","ConsignmentCommande")}></Route>
        <Route path="/product/consignment/sku" element={renderComponentBasedOnPermissions("product","ConsignmentSku")}></Route>
        <Route path="/product/consignment/order" element={renderComponentBasedOnPermissions("product","ConsignmentOrder")}></Route>
       

        <Route path="/product/tmallskinmira/sku" element={renderComponentBasedOnPermissions("product","ProductTmallskinmira")}></Route>
        <Route path="/product/tmallcharme/sku" element={renderComponentBasedOnPermissions("product","ProductTmallcharme")}></Route>
        <Route path="/product/approval/sku" element={renderComponentBasedOnPermissions("product","ProductTmallapproval")}></Route>
        <Route path="/product/approvalcharme/sku" element={renderComponentBasedOnPermissions("product","ProductTmallapprovalcharme")}></Route>
        
        <Route path="/product/standardProduct/sku" element={renderComponentBasedOnPermissions("product","StandardProduct")}></Route>

        <Route path="/product/xhs/sku" element={renderComponentBasedOnPermissions("product","Xhs")}></Route>
        <Route path="/product/mark" element={renderComponentBasedOnPermissions("product","Mark")}></Route>
        <Route path="/product/photo" element={renderComponentBasedOnPermissions("product","Photo")}></Route>
        <Route path="/product/grap/sku" element={renderComponentBasedOnPermissions("product","Grapsku")}></Route>
        <Route path="/product/grap/product" element={renderComponentBasedOnPermissions("product","Grapproduct")}></Route>
       <Route path="/product/grap/setting" element={renderComponentBasedOnPermissions("product","Grapsetting")}></Route>

        <Route path="/stock/stockEdit" element={renderComponentBasedOnPermissions("box","StockEdit")}></Route>
        <Route path="/stock/command" element={ renderComponentBasedOnPermissions("box","StockCommand")}></Route>
        <Route path="/stock/Checktostock" element={ renderComponentBasedOnPermissions("box","Checktostock")}></Route>

        <Route path="/Box/waybill" element={renderComponentBasedOnPermissions("box","Waybill")}></Route>
        <Route path="/Box/checking" element={renderComponentBasedOnPermissions("box","Checking")}></Route>
        <Route path="/Box/sorting" element={ renderComponentBasedOnPermissions("box","Sorting") }></Route>
        <Route path="/Box/weighing" element={ renderComponentBasedOnPermissions("box","Weighing") }></Route>
        <Route path="/Box/box" element={renderComponentBasedOnPermissions("box","Box") }></Route>
        <Route path="/Box/returner" element={ renderComponentBasedOnPermissions("box","returner") }></Route>

        <Route path="/purchase/sku" element={ renderComponentBasedOnPermissions("purchase","Purchasesku") }></Route>
       
        <Route path="/purchase/command" element={ renderComponentBasedOnPermissions("purchase","PurchaseCommand")}></Route>
        <Route path="/purchase/fournisseur" element={renderComponentBasedOnPermissions("purchase","fournisseur")}></Route>
        <Route path="/purchase/stockingUp" element={renderComponentBasedOnPermissions("purchase","stockingUp")}></Route>
        <Route path="/purchase/returner" element={renderComponentBasedOnPermissions("purchase","Preturner")}></Route>
        <Route path="/purchase/account" element={ renderComponentBasedOnPermissions("purchase","account")}></Route>
        <Route path="/purchase/pickup" element={ renderComponentBasedOnPermissions("purchase","pickup")}></Route>
        <Route path="/purchase/luxury" element={ renderComponentBasedOnPermissions("purchase","luxury")}></Route>

        <Route path="/invoice/liste" element={ renderComponentBasedOnPermissions("invoice","Invoice")}></Route>
        <Route path="/fonds/liste" element={ renderComponentBasedOnPermissions("fonds","Fonds")}></Route>

        <Route path="/account/liste" element={ renderComponentBasedOnPermissions("account","Account")}></Route>
    
      </Route>  
        
      
      
     
    </Routes>
    
  );
}

function PublicPage() {

  return <></>;
}

export default App;
/*PurchaseCommand
 <Route path="/Box/sorting" element={window.sessionStorage.login === 'true'?<Sorting />:<PublicPage />}></Route>
          <Route path="/Box/packaging" element={window.sessionStorage.login === 'true'?<Packaging />:<PublicPage />}></Route>
          <Route path="/Box/weighing" element={window.sessionStorage.login === 'true'?<Weighing />:<PublicPage />}></Route>
          <Route path="/Box/boxing" element={window.sessionStorage.login === 'true'?<Boxing />:<PublicPage />}></Route>
          <Route path="/Box/box" element={window.sessionStorage.login === 'true'?<Box />:<PublicPage />}></Route>

          */